import React, { useState, useEffect } from "react";
import CourseEnrolment from "./Charts/CourseEnrolment";
import StudentEngagement from "./Charts/StudentEngagement";
import ErrorReports from "./Charts/ErrorReports";
import CourseOverview from "./Charts/CourseOverview";
import EstorePerformance from "./Charts/EstorePerformance";
import CourseTimeEngagement from "./Charts/CourseTimeEngagement";
import CourseGradeEngagement from "./Charts/CourseGradeEngagement";
import GaugeChart from "react-gauge-chart";

import { useSelector } from "react-redux";

import UserService from "../../../services/user.service";
import GraphService from "../../../services/graph.service";
import AuthService from "../../../services/auth.service";
import CourseService from "../../../services/course.service";

const SADashboard = () => {
  const [lastSevenDates, setLastSevenDates] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);

  const [estoreCourses, setEstoreCourses] = useState([]);

  const [inactiveAdmins, setInactiveAdmins] = useState(0);
  const [activeAdmins, setActiveAdmins] = useState(0);

  const [yAxisData, setYAxisData] = useState([]);

  const themeMode = useSelector((state) => state.themeReducer.themeMode);

  const [courseEnrolmentData, setCourseEnrolmentData] = useState([]);
  const [courseEnrolmentValue, setCourseEnrolmentValue] = useState([]);
  const [courseCompletionValue, setCourseCompletionValue] = useState([]);

  const [studentEngagementData, setStudentEngagementData] = useState([]);
  const [estoreCourseData, setEstoreCourseData] = useState([]);
  const [estoreCourseValue, setEstoreCourseValue] = useState([]);
  const [learnerTimeData, setLearnerTimeData] = useState([]);
  const [learnerGradesData, setLearnerGradesData] = useState([]);

  const [defaultReportFilter, setDefaultReportFilter] = useState("");

  const [courseEnrollmentFilter, setCourseEnrollmentFilter] = useState("");
  const [studentEngagementFilter, setStudentEngagementFilter] = useState("");
  const [estoreCourseFilter, setEstoreCourseFilter] = useState("");
  const [learnerTimeFilter, setLearnerTimeFilter] = useState("");
  const [learnerGradesFilter, setLearnerGradesFilter] = useState("");

  const formatDateString = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}`;
  };

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const days =
      Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000)) + 1;
    const weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }

  const today = new Date();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    getStaffsData(user.user);
  }, [activeAdmins, inactiveAdmins]);

  useEffect(() => {
    getLastSevenDays();
    getMonths();
    getWeeks();
    getAllEstoreCourses();

    const user = AuthService.getCurrentUser();

    const data = {
      cid: estoreCourseFilter,
    };

    GraphService.getEstoreCourseGraph(data)
      .then((resp) => {
        setEstoreCourseValue(resp.data.last15Days.counts);
      })
      .catch((e) => console.log(e));

    GraphService.getCourseEnrollmentReport(user.user)
      .then((resp) => {
        const arr = resp.data.enrollmentLast15Days.split(",");
        const newArr = resp.data.compLast15Days.split(",");
        setCourseEnrolmentValue(arr);
        setCourseCompletionValue(newArr);
      })
      .catch((e) => console.log(e));
  }, []);

  const getAllEstoreCourses = () => {
    CourseService.getEstoreCourses()
      .then((resp) => {
        setEstoreCourses(resp.data);
        setEstoreCourseFilter(resp.data[0].course_id);
      })
      .catch((e) => console.log(e));
  };

  const getStaffsData = (uid) => {
    UserService.getSystemManagers(uid)
      .then((response) => {
        const staffsData = response.data;
        const inactiveCount = Array.isArray(staffsData)
          ? staffsData.filter((admin) => admin.status === "0").length
          : null;
        const activeCount = Array.isArray(staffsData)
          ? staffsData.filter((admin) => admin.status === "1").length
          : null;
        setInactiveAdmins(inactiveCount / response.data.length);
        setActiveAdmins(activeCount / response.data.length);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLastSevenDays = () => {
    const lastFifteenDates = [];
    const today = new Date();
    const currentDate = new Date(today);

    for (let i = 0; i < 15; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      lastFifteenDates.push(date);
    }

    const sortedDatesFifteen = lastFifteenDates.slice().sort((a, b) => a - b);

    const formattedDates = sortedDatesFifteen.map((date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      return `${day}-${month}`;
    });

    // Assuming these functions are available and you are handling state updates correctly
    setLastSevenDates(formattedDates);
    setCourseEnrolmentData(formattedDates);
    setStudentEngagementData(formattedDates);
    setEstoreCourseData(formattedDates);
    setLearnerTimeData(formattedDates);
    setLearnerGradesData(formattedDates);
  };

  const getMonths = () => {
    const lastSevenMonths = [];

    for (let i = 0; i < 12; i++) {
      const monthStartDate = new Date(today);
      monthStartDate.setMonth(monthStartDate.getMonth() - i);

      // const month = (monthStartDate.getMonth() + 1).toString().padStart(2, '0'); // Get month as two-digit string
      // const year = monthStartDate.getFullYear();
      // const formattedDate = `${month}-${year}`;

      lastSevenMonths.push(monthStartDate);
    }

    const sortedMonths = lastSevenMonths.slice().sort((a, b) => {
      const monthA = a.getMonth();
      const monthB = b.getMonth();
      const yearA = a.getFullYear();
      const yearB = b.getFullYear();

      if (yearA === yearB) {
        return monthA - monthB;
      } else {
        return yearA - yearB;
      }
    });

    const formattedDates = sortedMonths.map((date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${month}-${year}`;
    });

    setMonths(formattedDates);
  };

  const getWeeks = () => {
    const lastSevenWeeks = [];

    for (let i = 0; i < 10; i++) {
      const weekStartDate = new Date(today);
      weekStartDate.setDate(today.getDate() - i * 7);
      const weekNumber = getWeekNumber(weekStartDate);
      const formattedWeek = `Week ${weekNumber}`;

      lastSevenWeeks.push({ formattedWeek, startDate: weekStartDate });
    }

    lastSevenWeeks.sort((a, b) => {
      return a.startDate - b.startDate;
    });

    const sortedWeeks = lastSevenWeeks.map((week) => week.formattedWeek);

    setWeeks(sortedWeeks);
  };

  const filterCourseEnrollment = (e) => {
    setCourseEnrollmentFilter(e.target.value);
    if (e.target.value == 0) {
      setCourseEnrolmentData(lastSevenDates);
      const user = AuthService.getCurrentUser();
      GraphService.getCourseEnrollmentReport(user.user)
        .then((resp) => {
          const arr = resp.data.enrollmentLast15Days.split(",");
          const newArr = resp.data.compLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          setCourseCompletionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 1) {
      setCourseEnrolmentData(weeks);
      const user = AuthService.getCurrentUser();
      GraphService.getCourseEnrollmentReport(user.user)
        .then((resp) => {
          const arr = resp.data.enrollmentLastWeek.split(",");
          const newArr = resp.data.compLastWeek.split(",");
          setCourseEnrolmentValue(arr);
          setCourseCompletionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 2) {
      setCourseEnrolmentData(months);
      const user = AuthService.getCurrentUser();
      GraphService.getCourseEnrollmentReport(user.user)
        .then((resp) => {
          const arr = resp.data.enrollmentLastMonth.split(",");
          const newArr = resp.data.compLastMonth.split(",");
          setCourseEnrolmentValue(arr);
          setCourseCompletionValue(newArr);
        })
        .catch((e) => console.log(e));
    }
  };

  const filterStudentEngagement = (e) => {
    setStudentEngagementFilter(e.target.value);
    if (e.target.value == 0) {
      setStudentEngagementData(lastSevenDates);
    } else if (e.target.value == 1) {
      setStudentEngagementData(weeks);
    } else if (e.target.value == 2) {
      setStudentEngagementData(months);
    }
  };

  const filterEstorePerformance = (e) => {
    setEstoreCourseFilter(e.target.value);

    const data = {
      cid: e.target.value,
    };

    GraphService.getEstoreCourseGraph(data)
      .then((resp) => {
        setEstoreCourseValue(resp.data.last15Days.counts);
      })
      .catch((e) => console.log(e));
  };

  const filterLearnerTime = (e) => {
    setLearnerTimeFilter(e.target.value);
    if (e.target.value == 0) {
      setLearnerTimeData(lastSevenDates);
    } else if (e.target.value == 1) {
      setLearnerTimeData(weeks);
    } else if (e.target.value == 2) {
      setLearnerTimeData(months);
    }
  };

  const filterLearnerGrades = (e) => {
    setLearnerGradesFilter(e.target.value);
    if (e.target.value == 0) {
      setLearnerGradesData(lastSevenDates);
    } else if (e.target.value == 1) {
      setLearnerGradesData(weeks);
    } else if (e.target.value == 2) {
      setLearnerGradesData(months);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 mb-3">
            <div className="outer-box three">
              <div className="heading">
                <h1>Reports</h1>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Student Course Enrollment</h1>
                      </div>
                      <div className="error-req flex-end">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={courseEnrollmentFilter}
                              onChange={filterCourseEnrollment}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <CourseEnrolment
                      courseEnrolmentData={courseEnrolmentData}
                      courseEnrolmentValue={courseEnrolmentValue}
                      courseCompletionValue={courseCompletionValue}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Student Engagement</h1>
                      </div>
                      <div className="error-req flex-end">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={studentEngagementFilter}
                              onChange={filterStudentEngagement}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <StudentEngagement
                      studentEngagementData={studentEngagementData}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 mb-3">
            <div className="outer-box two">
              <div className="row">
                <div className="col-xl-4 col-lg-4">
                  <div className="inner-box">
                    <div className="heading">
                      <h1>Error reports and Service Requrests</h1>
                    </div>
                    <ErrorReports />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="inner-box">
                    <div className="heading">
                      <h1>Manage People</h1>
                    </div>
                    <div className="mng-ppl-chart">
                      <GaugeChart
                        id="gauge-chart1"
                        nrOfLevels={2}
                        colors={["#388BFD", "#E8E8E8"]}
                        percent={activeAdmins}
                        needleBaseColor="#3F3E40"
                        needleColor="#3F3E40"
                        arcsLength={[activeAdmins, inactiveAdmins]}
                        // arcsLength={[0.6, 0.4]}
                        arcPadding={0}
                        cornerRadius={0}
                        arcWidth={0.3}
                        // style={{'background': 'transparent linear-gradient(90deg, #CAE8FF 0%, #388BFD 100%) 0% 0% no-repeat padding-box;'}}
                      />
                      <div className="inactiveAdmin">
                        <p>Active Admin</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="inner-box">
                    <div className="heading">
                      <h1>Manage Courses</h1>
                    </div>
                    <CourseOverview />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12">
            <div className="outer-box three">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="inner-box estore">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Estore Courses Performance</h1>
                      </div>
                      <div className="error-req course">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={estoreCourseFilter}
                              onChange={filterEstorePerformance}
                            >
                              <option value="0">Select course</option>
                              {Array.isArray(estoreCourses)
                                ? estoreCourses.map((estoreCourse) => {
                                    return (
                                      <>
                                        <option value={estoreCourse.course_id}>
                                          {estoreCourse.coursename}
                                        </option>
                                      </>
                                    );
                                  })
                                : null}
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <EstorePerformance
                      estoreCourseData={estoreCourseData}
                      estoreCourseValue={estoreCourseValue}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-12 col-lg-12">
            <div className="outer-box four">
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Course wise learner time engagement</h1>
                      </div>
                      <div className="error-req">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={learnerTimeFilter}
                              onChange={filterLearnerTime}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CourseTimeEngagement
                      learnerTimeData={learnerTimeData}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Course wise Learner Grade</h1>
                      </div>
                      <div className="error-req">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={learnerGradesFilter}
                              onChange={filterLearnerGrades}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CourseGradeEngagement
                      learnerGradesData={learnerGradesData}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SADashboard;
