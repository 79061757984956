import React, { useRef, useEffect } from "react";
import Iframe from "react-iframe";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CourseService from "../services/course.service";
import AuthService from "../services/auth.service";
import { Watch } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoadingGif from "../../src/assets/images/Loading.gif";

const Player = ({
  aid,
  cid,
  getCourseActivities,
  getCourseCompSts,
  activitySts,
  attemptCount,
  attempt,
  fetchActivityResults,
  file_name,
  activity_type,
}) => {
  const [attempts, setAttempts] = useState("");

  const [attemptCounts, setAttemptCounts] = useState("");

  const [scrom, setScrom] = useState({});
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState("");

  const iframeRef = useRef(null);

  const [isFunctionRunning, setIsFunctionRunning] = useState(false);

  const navigate = useNavigate();

  const videoRef = useRef(null);

  const [duration, setDuration] = useState(0);

  const [currentTime, setCurrentTime] = useState(0);

  const [totalPlayTime, setTotalPlayTime] = useState(0);

  const [mediaStatus, setMediaStatus] = useState("");

  const [activityResult, setActivityResult] = useState({
    strscore_max: "",
    strscore_min: "",
    strscore_raw: "",
    strsession_time: "",
    strlesson_status: "",
    course_id: cid,
    activity_id: aid,
    activity_typeid: "",
    student_id: "",
  });

  useEffect(() => {}, []);

  const scromRun = (cid, aid, user) => {
    let activityRes = {};

    const tspInit = (window, storage, prefix, callback) => {
      prefix = typeof prefix !== "undefined" ? prefix : "";
      callback = typeof callback !== "undefined" ? callback : console.log;

      window.API = {};

      window.scormStatus = {
        lesson_status: "",
        score_raw: 0,
        score_max: 100,
        score_min: 0,
        session_time: 0,
        detailed_answers: {},
        lesson_location: "",
      };

      window.API.LMSInitialize = function () {};
      window.API.LMSTerminate = function () {};

      window.API.LMSGetValue = function (varname) {
        varname = prefix + varname;
        var ret = storage.getItem(varname);
        if (
          ret == null &&
          varname.indexOf("_count", this.length - "_count".length) !== -1
        ) {
          ret = 0;
          storage.setItem(varname, ret);
        }

        return ret;
      };

      window.API.LMSSetValue = function (varname, varvalue) {
        varname = prefix + varname;

        var m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.id/);
        if (m != null) {
          storage.setItem(
            "{{scorm.id}}.cmi.interactions._count",
            parseInt(m[2]) + 1
          );
        }

        m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.result/);
        if (m != null) {
          var key = storage.getItem(
            prefix + "cmi.interactions." + parseInt(m[2]) + ".id"
          );
          window.scormStatus.detailed_answers[key] = varvalue;
        }

        if (varname === prefix + "cmi.core.lesson_status")
          window.scormStatus.lesson_status = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });
        if (varname === prefix + "cmi.core.score.raw")
          window.scormStatus.score_raw = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_raw: window.scormStatus.score_raw,
        });
        if (varname === prefix + "cmi.core.score.max")
          window.scormStatus.score_max = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_max: window.scormStatus.score_max,
        });
        if (varname === prefix + "cmi.core.score.min")
          window.scormStatus.score_min = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_min: window.scormStatus.score_min,
        });
        if (varname === prefix + "cmi.core.session_time")
          window.scormStatus.session_time = varvalue;
        setActivityResult({
          ...activityResult,
          strsession_time: window.scormStatus.session_time,
        });

        if (varname === prefix + "cmi.core.lesson_location")
          window.scormStatus.lesson_location = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });

        storage.setItem(varname, varvalue);
      };

      window.API.LMSCommit = function () {
        //saving to API
        callback(window.scormStatus);
        return true;
      };

      window.API.LMSFinish = function () {
        uploadResult(activityRes);
      };

      window.API.LMSGetLastError = function () {};

      window.API.LMSGetErrorString = function () {};

      window.API.LMSGetDiagnostic = function () {};
    };

    const supports_html5_storage = () => {
      try {
        return "localStorage" in window && window["localStorage"] !== null;
      } catch (e) {
        return false;
      }
    };

    if (!supports_html5_storage()) {
      window.localStorage = {};
    }

    tspInit(
      window,
      window.localStorage,
      //this has to be unique per each scorm you serve
      "SCORM_ID.",
      function (progress) {
        //this will be called whenever student makes a progress in test.

        if (
          progress.score_raw === "0" &&
          progress.lesson_status === "completed"
        ) {
          activityRes = {
            strscore_max: progress.score_max,
            strscore_min: progress.score_min,
            strscore_raw: "100",
            strsession_time: progress.session_time,
            strlesson_status: progress.lesson_status,
            course_id: cid,
            activity_id: aid,
            student_id: user.user,
            activity_typeid: "1",
          };
        } else {
          activityRes = {
            strscore_max: progress.score_max,
            strscore_min: progress.score_min,
            strscore_raw: progress.score_raw,
            strsession_time: progress.session_time,
            strlesson_status: progress.lesson_status,
            course_id: cid,
            activity_id: aid,
            student_id: user.user,
            activity_typeid: "1",
          };
        }
      }
    );
  };

  const handlePlay = () => {
    const video = videoRef.current;

    if (video) {
      let durationInMinutes = Math.floor(video.duration / 60);
      let durationInSeconds = durationInMinutes % 60;

      setDuration(Math.floor(video.duration));

      const intervalId = setInterval(() => {
        const unit = "seconds";
        setCurrentTime(Math.round(video.currentTime) + " " + unit);
        setTotalPlayTime((prevTotalPlayTime) => prevTotalPlayTime + 1);
      }, 1000);

      // Clear the interval when the video is paused or ended
      video.onpause = video.onended = () => {
        clearInterval(intervalId);
      };
    }
  };

  const handlePause = () => {
    uploadMediaRes();
  };

  const uploadResult = (data) => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    CourseService.uploadScromResult(data)
      .then((res) => {
        fetchActivityResults(datanew, fetchResdata);
        getCourseActivities();
        getCourseCompSts(user.user, cid);
      })
      .catch((e) => console.log(e));
  };

  const uploadMediaRes = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: totalPlayTime,
      strlesson_status: mediaStatus,
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        fetchActivityResults(datanew, fetchResdata);
        getCourseActivities();
        getCourseCompSts(user.user, cid);
      })
      .catch((e) => console.log(e));
  };

  const handleIframeLoad = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: "",
      strlesson_status: "completed",
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        setIsFunctionRunning(true);

        fetchActivityResults(datanew, fetchResdata);
        getCourseActivities();
        getCourseCompSts(user.user, cid);
      })
      .catch((e) => {
        console.log(e);
        setIsFunctionRunning(false); // Make sure to set the flag even in case of an error.
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setAttempts(attempt);
    setAttemptCounts(attemptCount);

    setCurrentUser(user.user);

    setActivityResult({ ...activityResult, student_id: user.user });

    scromRun(cid, aid, user);

    if (totalPlayTime >= duration) {
      setMediaStatus("Completed");
    } else {
      setMediaStatus("Not complete");
    }
  }, [aid]);

  return (
    <>
      <ToastContainer />

      {attempt !== "0" && attemptCount >= attempt ? (
        <>
          <div className="loadinggif-flex">
            <img src={LoadingGif} className="loadinggif" alt="" />
            <span>You have reached the maximum number of attempts.</span>
          </div>
        </>
      ) : (
        <>
          {file_name.includes("mp4") ? (
            <>
              <video
                ref={videoRef}
                id="myvideo"
                width="100%"
                onPlay={handlePlay}
                onPause={handlePause}
                controls
                controlsList="nodownload"
                onContextMenu={() => false}
              >
                <source
                  src={"https://lms.aeonsoftware.net/cit/" + file_name}
                  type="video/mp4"
                />
              </video>
            </>
          ) : activity_type === "2" && file_name.includes(".pdf") ? (
            <>
              <Iframe
                url={"https://lms.aeonsoftware.net/cit/" + file_name}
                id="pdfPlayer"
                width="640px"
                height="320px"
                display="block"
                position="relative"
                ref={iframeRef}
                className="mb-3"
              />

              {isFunctionRunning &&
              activitySts &&
              activitySts === "Completed" ? (
                <></>
              ) : (
                <>
                  <button className="mark-comp" onClick={handleIframeLoad}>
                    Mark as Completed
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <Iframe
                url={
                  "https://lms.aeonsoftware.net/cit/" +
                  file_name +
                  "/launcher.html"
                }
                id="coursePlayer"
                width="640px"
                height="320px"
                className=""
                display="block"
                position="relative"
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export default Player;
