import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EditProfile.css";
import AuthService from "../services/auth.service";
import { Form, Alert } from "react-bootstrap";
import UserService from "../services/user.service";

const EditProfile = () => {
  const [profileDetails, setProfileDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    designation: "",
    qualification: "",
    organization: "",
  });

  const [roleid, setRoleid] = useState("");
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [newName, setNewName] = useState("");

  const [validated, setValidated] = useState(false);

  const [changeData, setChangeData] = useState(false);

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setRoleid(user.role_id);
      setUserId(user.user);
      setName(user.name);
    }
    getProfile(user.user);
  }, []);

  const handleChange = (event) => {
    // const { name, value } = event.target;
    // // setProfileDetails({ ...profileDetails, [name]: value });
    // setChangeData(true);
    // const data = event.target.value;
    // const dataName = event.target.name;
    // if (dataName !== "email") {
    //   if (/^[a-zA-Z]*$/.test(data))
    //     setProfileDetails({ ...profileDetails, [name]: value });
    // } else {
    //   if (dataName == "email") {
    //     if (/^[a-zA-Z]*$/.test(dataName))
    //       setProfileDetails({ ...profileDetails, [name]: value });
    //   }
    // }

    const { name, value } = event.target;
    if (name !== "email" && name !== "organization") {
      if (/^[a-zA-Z.]*$/.test(value)) {
        setProfileDetails({ ...profileDetails, [name]: value });
      }
    } else {
      setProfileDetails({ ...profileDetails, [name]: value });
    }
  };

  const getProfile = (pid) => {
    UserService.getProfileById(pid)
      .then((resp) => {
        setProfileDetails(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateProfile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast.warn("*Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setValidated(true);
    event.preventDefault();

    const user = AuthService.getCurrentUser();

    if (user.role_id === "4") {
      const editedDetails = {
        role_id: roleid,
        user_id: profileDetails.user_id,
        first_name: profileDetails.first_name,
        last_name: profileDetails.last_name,
        email: profileDetails.email,
        qualification: profileDetails.qualification,
        organization: profileDetails.organization,
      };

      UserService.updateProfile(editedDetails)
        .then((response) => {
          toast.success(response.data.massage, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setChangeData(false);
          getProfile(userId);

          localStorage.setItem(
            "name",
            JSON.stringify(editedDetails.first_name)
          );
          setNewName(JSON.parse(localStorage.getItem("name")));
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const editedDetails = {
        role_id: roleid,
        user_id: profileDetails.user_id,
        first_name: profileDetails.first_name,
        last_name: profileDetails.last_name,
        email: profileDetails.email,
        designation: profileDetails.designation,
      };

      UserService.updateProfile(editedDetails)
        .then((response) => {
          toast.success(response.data.massage, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setChangeData(false);
          getProfile(userId);

          localStorage.setItem(
            "name",
            JSON.stringify(editedDetails.first_name)
          );
          setNewName(JSON.parse(localStorage.getItem("name")));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer addUser">
              <div className="box-inner">
                <div className="heading">
                  <h1>Edit Profile</h1>
                </div>
                <div className="profile-img-container">
                  <div className="circle">
                    <img
                      src={
                        "https://lms.aeonsoftware.net/cit/uploads/images/user.png"
                      }
                      alt="Profile-Image"
                    />
                  </div>
                  <div className="profile-details">
                    <span className="profile-name" style={{ fontSize: 40 }}>
                      {newName ? newName : name}
                    </span>
                    <span className="profile-id">User Id: {userId}</span>
                  </div>
                </div>
                {error ? (
                  <>
                    <Alert className="m-auto mb-5" variant="danger">
                      {errMessage}
                    </Alert>
                  </>
                ) : (
                  <></>
                )}
                <Form noValidate validated={validated} onSubmit={updateProfile}>
                  {roleid === "1" || roleid === "2" || roleid === "3" ? (
                    <div className="add-user-form">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>First Name</Form.Label>
                            <div className="input-box">
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                name="first_name"
                                value={profileDetails.first_name}
                                onChange={handleChange}
                                validations={[required]}
                              />
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 20.199 20.201"
                                >
                                  <g
                                    id="Group_11227"
                                    data-name="Group 11227"
                                    transform="translate(-2402.326 -6200.833)"
                                  >
                                    <path
                                      id="Path_11890"
                                      data-name="Path 11890"
                                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                      transform="translate(752.656 5510.435)"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_11892"
                                      data-name="Path 11892"
                                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                      transform="translate(751.221 5511.583)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <div className="input-box">
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                name="last_name"
                                value={profileDetails.last_name}
                                onChange={handleChange}
                                validations={[required]}
                              />
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 20.199 20.201"
                                >
                                  <g
                                    id="Group_11227"
                                    data-name="Group 11227"
                                    transform="translate(-2402.326 -6200.833)"
                                  >
                                    <path
                                      id="Path_11890"
                                      data-name="Path 11890"
                                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                      transform="translate(752.656 5510.435)"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_11892"
                                      data-name="Path 11892"
                                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                      transform="translate(751.221 5511.583)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <div className="input-box">
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            value={profileDetails.email}
                            // onChange={handleChange}
                            // onChange={(e) => setEmail(e.target.value)}
                            readOnly
                            validations={[required]}
                          />
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 20.199 20.201"
                            >
                              <g
                                id="Group_11227"
                                data-name="Group 11227"
                                transform="translate(-2402.326 -6200.833)"
                              >
                                <path
                                  id="Path_11890"
                                  data-name="Path 11890"
                                  d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                  transform="translate(752.656 5510.435)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <path
                                  id="Path_11892"
                                  data-name="Path 11892"
                                  d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                  transform="translate(751.221 5511.583)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicDesignation"
                      >
                        <Form.Label>Designation</Form.Label>
                        <div className="input-box">
                          <Form.Control
                            type="text"
                            placeholder="Enter Designation"
                            name="designation"
                            value={profileDetails.designation}
                            onChange={handleChange}
                            validations={[required]}
                          />
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 20.199 20.201"
                            >
                              <g
                                id="Group_11227"
                                data-name="Group 11227"
                                transform="translate(-2402.326 -6200.833)"
                              >
                                <path
                                  id="Path_11890"
                                  data-name="Path 11890"
                                  d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                  transform="translate(752.656 5510.435)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <path
                                  id="Path_11892"
                                  data-name="Path 11892"
                                  d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                  transform="translate(751.221 5511.583)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </Form.Group>
                    </div>
                  ) : (
                    <div className="add-user-form">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>First Name</Form.Label>
                            <div className="input-box">
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                required
                                name="first_name"
                                value={profileDetails.first_name}
                                onChange={handleChange}
                                validations={[required]}
                              />
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 20.199 20.201"
                                >
                                  <g
                                    id="Group_11227"
                                    data-name="Group 11227"
                                    transform="translate(-2402.326 -6200.833)"
                                  >
                                    <path
                                      id="Path_11890"
                                      data-name="Path 11890"
                                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                      transform="translate(752.656 5510.435)"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_11892"
                                      data-name="Path 11892"
                                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                      transform="translate(751.221 5511.583)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <div className="input-box">
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                required
                                name="last_name"
                                value={profileDetails.last_name}
                                onChange={handleChange}
                                validations={[required]}
                              />
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 20.199 20.201"
                                >
                                  <g
                                    id="Group_11227"
                                    data-name="Group 11227"
                                    transform="translate(-2402.326 -6200.833)"
                                  >
                                    <path
                                      id="Path_11890"
                                      data-name="Path 11890"
                                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                      transform="translate(752.656 5510.435)"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_11892"
                                      data-name="Path 11892"
                                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                      transform="translate(751.221 5511.583)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <div className="input-box">
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            required
                            value={profileDetails.email}
                            onChange={handleChange}
                            // onChange={email}
                            // readOnly
                            validations={[required]}
                          />
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 20.199 20.201"
                            >
                              <g
                                id="Group_11227"
                                data-name="Group 11227"
                                transform="translate(-2402.326 -6200.833)"
                              >
                                <path
                                  id="Path_11890"
                                  data-name="Path 11890"
                                  d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                  transform="translate(752.656 5510.435)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <path
                                  id="Path_11892"
                                  data-name="Path 11892"
                                  d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                  transform="translate(751.221 5511.583)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicDesignation"
                      >
                        <Form.Label>Qualification</Form.Label>
                        <div className="input-box">
                          <Form.Control
                            type="text"
                            placeholder="Enter Qualification"
                            name="qualification"
                            required
                            value={profileDetails.qualification}
                            onChange={handleChange}
                            validations={[required]}
                          />
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 20.199 20.201"
                            >
                              <g
                                id="Group_11227"
                                data-name="Group 11227"
                                transform="translate(-2402.326 -6200.833)"
                              >
                                <path
                                  id="Path_11890"
                                  data-name="Path 11890"
                                  d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                  transform="translate(752.656 5510.435)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <path
                                  id="Path_11892"
                                  data-name="Path 11892"
                                  d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                  transform="translate(751.221 5511.583)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicDesignation"
                      >
                        <Form.Label>Affiliation/Organisation</Form.Label>
                        <div className="input-box">
                          <Form.Control
                            type="text"
                            placeholder="Enter affiliation/organization"
                            required
                            name="organization"
                            value={profileDetails.organization}
                            onChange={handleChange}
                            validations={[required]}
                          />
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 20.199 20.201"
                            >
                              <g
                                id="Group_11227"
                                data-name="Group 11227"
                                transform="translate(-2402.326 -6200.833)"
                              >
                                <path
                                  id="Path_11890"
                                  data-name="Path 11890"
                                  d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                  transform="translate(752.656 5510.435)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <path
                                  id="Path_11892"
                                  data-name="Path 11892"
                                  d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                  transform="translate(751.221 5511.583)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </Form.Group>
                    </div>
                  )}

                  <ToastContainer />

                  <div className="act-btn">
                    <button disabled={!changeData}>Submit</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
