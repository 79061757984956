import React, { useState, useEffect } from "react";

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CourseGradeEngagement = ({ learnerGradesData, themeMode }) => {
  var style = getComputedStyle(document.body);

  if (themeMode === "0") {
    var whiteColor = "#f5f5f5";
  } else if (themeMode === "1") {
    var whiteColor = "#010001";
  } else {
    var whiteColor = style.getPropertyValue("--whiteColor");
  }

  const data = {
    labels: learnerGradesData,
    datasets: [
      {
        label: "Course 1",
        data: [12, 20, 15, 25, 12, 15, 10, 15, 10, 15, 25, 15, 20, 15, 20],
        backgroundColor: "#8F439B",
        borderColor: "#8F439B",
        color: "#FFFFFF",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
      {
        label: "Course 2",
        data: [18, 25, 20, 30, 15, 18, 25, 20, 30, 15, 18, 25, 20, 30, 15],
        backgroundColor: "#9364EE",
        borderColor: "#9364EE",
        color: "#FFFFFF",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
      {
        label: "Course 3",
        data: [20, 30, 25, 35, 20, 30, 15, 25, 35, 20, 32, 15, 35, 20, 25],
        backgroundColor: "#DE74DA",
        borderColor: "#DE74DA",
        color: "#FFFFFF",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: (ctx) => "Course wise learner time engagement",
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          color: whiteColor,
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <>
      <div className="line-area">
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default CourseGradeEngagement;
