import http from "../http-common";

const token = JSON.parse(localStorage.getItem("token"));

const getCourseEnrollmentReport = (baps) => {
  return http.get(`/api/graphinfo?baps=${baps}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getStudentCourseProgress = (uid) => {
  return http.get(`/api/graphstdcors?uid=${uid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};


const getStudentEnrollmentReportByCourseId = (cid) => {
  return http.get(`/api/graphcsdata?cid=${cid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getEstoreCourseGraph = (data) => {
  return http.post(`/api/getestatrans`, data, {
    headers: { Authorization: "Bearer " + token },
  });
};




const GraphService = {
  getCourseEnrollmentReport,
  getStudentCourseProgress,
  getStudentEnrollmentReportByCourseId,
  getEstoreCourseGraph
};

export default GraphService;
