import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ErrorreqService from "../services/errorreq.service";

import CourseImg from "../assets/images/course-placeholder.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import "../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import 'datatables.net-responsive';
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import CourseService from "../services/course.service";
import AuthService from "../services/auth.service";
import { filter } from "lodash";
import EstoreService from "../services/estore.service";

const EstoreCoursesList = ({
  permissions,
  roleid,
  selectedOccuCat,
  selectedCourseCat,
}) => {
  const location = useLocation();

  // declarations
  const [show, setShow] = useState(false);

  const [courses, setCourses] = useState([]);

  const [isEstore, setIsEstore] = useState(true);

  const [activeCourses, setActiveCourses] = useState("0");
  const [inactiveCourses, setInactiveCourses] = useState("0");
  const [draftCourses, setDraftCourses] = useState("0");

  const [learnersInCourse, setLearnersInCourse] = useState("0");

  const [supportIssues, setSupportIssues] = useState("0");

  const [userid, setUserid] = useState();
  const [did, setDid] = useState("");

  // functions

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const fetchCourseData = (uid) => {
    const data = {
      usp: uid,
      rid: "",
    };
    CourseService.getEstoreCourses(data)
      .then((response) => {
        const newData = response.data;
        setCourses(newData);
        loadDatatable();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadDatatable = () => {
    if (!$.fn.DataTable.isDataTable("#estorecourses")) {
      function filterGlobal() {
        $("#estorecourses")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#estorecourses")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $.fn.dataTable.ext.search.pop();

      // Function to apply the custom filter
      function applyStaffsStatusFilter(status) {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var rowData = data[1];

          if (rowData === status) {
            return true;
          }
          return false;
        });

        $("#estorecourses").DataTable().draw();
      }

      function applyOccupCatFilter(roleName) {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var rowData = data[3];

          if (rowData === roleName) {
            return true;
          }
          return false;
        });

        $("#estorecourses").DataTable().draw();
      }

      function applyCourseCatFilter(roleName) {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var rowData = data[4];

          if (rowData === roleName) {
            return true;
          }
          return false;
        });

        $("#estorecourses").DataTable().draw();
      }

      function compareDates() {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var minDate = $("#minDate").val();
          var maxDate = $("#maxDate").val();

          let date = data[2];

          if (minDate === date || (minDate <= date && date <= maxDate)) {
            return true;
          }
          return false;
        });

        $("#estorecourses").DataTable().draw();

        // Clear the custom filter if the date input is empty
        if (!$(this).val()) {
          $.fn.dataTable.ext.search.pop();
        }
      }

      // Button click event handlers
      $("#activeCourses").on("click", function () {
        applyStaffsStatusFilter("1");
      });

      $("#inactiveCourses").on("click", function () {
        applyStaffsStatusFilter("0");
      });

      $("#draftCourses").on("click", function () {
        applyStaffsStatusFilter("2");
      });

      $("#allCourses").on("click", function () {
        // Remove the custom filter
        $.fn.dataTable.ext.search.pop();
        $("#estorecourses").DataTable().draw();
      });

      $("#minDate, #maxDate").on("change", function () {
        compareDates();
      });

      $("#filterOccupCat").on("change", function () {
        var selectedValue = $(this).val();

        applyOccupCatFilter(selectedValue);
      });

      $("#filterCourseCat").on("change", function () {
        var selectedValue = $(this).val();

        applyCourseCatFilter(selectedValue);
      });

      $("#clearFilter").on("click", function () {
        $.fn.dataTable.ext.search.pop();
        $("#estorecourses").DataTable().draw();
        $("#minDate").val("");
        $("#maxDate").val("");
      });

      $(document).ready(function () {
        setTimeout(function () {
          $("#estorecourses").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,
            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
  };

  const filterOccuData = (uid) => {
    CourseService.getCourse(uid)
      .then((response) => {
        const newData = response.data;

        if (selectedOccuCat !== "0") {
          let filterData = Array.isArray(newData)
            ? newData.filter(
                (course) => course.category_name === selectedOccuCat
              )
            : null;
          setCourses(filterData);

          loadDatatable();
        } else {
          setCourses(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterCourseData = (uid) => {
    CourseService.getCourse(uid)
      .then((response) => {
        const newData = response.data;

        if (selectedCourseCat !== "0") {
          let filterData = Array.isArray(newData)
            ? newData.filter(
                (course) => course.course_catename === selectedCourseCat
              )
            : null;
          setCourses(filterData);

          loadDatatable();
        } else {
          setCourses(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterCatData = (uid) => {
    // let user = AuthService.getCurrentUser();
    CourseService.getCourse(uid)
      .then((response) => {
        const newData = response.data;

        if (selectedOccuCat !== "0" && selectedCourseCat !== "0") {
          let filterData = Array.isArray(newData)
            ? newData.filter(
                (course) =>
                  course.category_name === selectedOccuCat &&
                  course.course_catename === selectedCourseCat
              )
            : null;
          setCourses(filterData);

          loadDatatable();
        } else {
          setCourses(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let user = AuthService.getCurrentUser();

    setUserid(user.user);

    if (user) {
      fetchCourseData(user.user);
    }
  }, []);

  useEffect(() => {
    let user = AuthService.getCurrentUser();

    if (selectedOccuCat && !selectedCourseCat) {
      filterOccuData(user.user);
    } else if (selectedCourseCat && !selectedOccuCat) {
      filterCourseData(user.user);
    } else if (selectedOccuCat && selectedCourseCat) {
      filterCatData(user.user);
    } else {
      fetchCourseData(user.user);
    }
  }, [selectedOccuCat, selectedCourseCat]);

  const handleDeleteCourse = () => {
    const user = AuthService.getCurrentUser();
    const data = {
      corsid: did,
    };

    EstoreService.deleteEstoreCourses(data)
      .then((resp) => {
        setShow(false);
        toast.success("Course has been removed from ecommerce!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        fetchCourseData(user.user);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <ToastContainer />

      <div
        className={
          location.pathname.includes("/Dashboard") || location.pathname === "/"
            ? "admin-users courses-list dash-course-list"
            : "admin-users courses-list"
        }
      >
        <div className="table-responsive">
          <table className="table" id="estorecourses">
            <thead>
              <tr>
                <th>Name</th>
                <th>Price</th>
                {/* <th hidden>Date</th> */}
                <th hidden>Occup Cat</th>
                <th hidden>Course Cat</th>
                <th>Validity</th>

                {(permissions &&
                  permissions.includes("mc3") &&
                  permissions.includes("mc4")) ||
                roleid === "1" ||
                roleid === "2" ? (
                  <>
                    <th
                      style={{ textAlign: "center", width: "50px" }}
                      className="action"
                    >
                      Action
                    </th>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(courses)
                ? courses.map((course) => (
                    <tr key={course.id}>
                      <td>
                        <div className="user-flex">
                          <div className="user-pic course-img">
                            {course.corsimg ? (
                              <>
                                <img
                                  src={
                                    "https://lms.aeonsoftware.net/cit/" +
                                    course.corsimg
                                  }
                                  height="50px"
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                <img src={CourseImg} height="50px" alt="" />
                              </>
                            )}
                          </div>
                          <div className="user-info">
                            <span className="name">{course.coursename}</span>
                            {/* <span className="role">{course.email}</span> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <span>{course.price}</span>
                      </td>
                      {/* <td hidden>{course.cexpdate}</td> */}
                      <td hidden>{course.course_catename}</td>
                      <td hidden>{course.course_catename}</td>
                      <td>
                        <span>{course.cexpdate}</span>
                      </td>

                      {(permissions &&
                        permissions.includes("mc3") &&
                        permissions.includes("mc4")) ||
                      roleid === "1" ||
                      roleid === "2" ? (
                        <>
                          <td>
                            <div className="table-act">
                              {course.pubsts === "0" ? (
                                <>
                                  {(permissions &&
                                    permissions.includes("mc3")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button className="edit">
                                        <Link
                                          to="/EditCourse"
                                          state={{
                                            cid: course.id,
                                            isEstore: true,
                                          }}
                                        >
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="15"
                                              viewBox="0 0 22.58 22.583"
                                            >
                                              <g
                                                id="Group_11449"
                                                data-name="Group 11449"
                                                transform="translate(-2402.326 -6200.833)"
                                              >
                                                <path
                                                  id="Path_11890"
                                                  data-name="Path 11890"
                                                  d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                  transform="translate(752.656 5510.995)"
                                                  fill="none"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                                />
                                                <path
                                                  id="Path_11892"
                                                  data-name="Path 11892"
                                                  d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                  transform="translate(751.921 5511.583)"
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        </Link>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {(permissions &&
                                    permissions.includes("mc4")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button
                                        onClick={handleShow}
                                        className="delete"
                                        title="Delete Course"
                                        data-bs-target={course.course_id}
                                      >
                                        <span>
                                          <svg
                                            id="delete"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="15"
                                            viewBox="0 0 16.079 20.664"
                                          >
                                            <path
                                              id="Path_13633"
                                              data-name="Path 13633"
                                              d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                              transform="translate(-57 512)"
                                            />
                                            <path
                                              id="Path_13634"
                                              data-name="Path 13634"
                                              d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                              transform="translate(-83.869 402.606)"
                                            />
                                          </svg>
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {(permissions &&
                                    permissions.includes("mc3")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button className="edit">
                                        <Link
                                          to="/EditCourse"
                                          state={{
                                            cid: course.course_id,
                                            isEstore: true,
                                          }}
                                        >
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="15"
                                              viewBox="0 0 22.58 22.583"
                                            >
                                              <g
                                                id="Group_11449"
                                                data-name="Group 11449"
                                                transform="translate(-2402.326 -6200.833)"
                                              >
                                                <path
                                                  id="Path_11890"
                                                  data-name="Path 11890"
                                                  d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                  transform="translate(752.656 5510.995)"
                                                  fill="none"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"
                                                />
                                                <path
                                                  id="Path_11892"
                                                  data-name="Path 11892"
                                                  d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                  transform="translate(751.921 5511.583)"
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        </Link>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {(permissions &&
                                    permissions.includes("mc4")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button
                                        onClick={handleShow}
                                        className="delete"
                                        title="Delete Course"
                                        data-bs-target={course.course_id}
                                      >
                                        <span>
                                          <svg
                                            id="delete"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="15"
                                            viewBox="0 0 16.079 20.664"
                                          >
                                            <path
                                              id="Path_13633"
                                              data-name="Path 13633"
                                              d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                              transform="translate(-57 512)"
                                            />
                                            <path
                                              id="Path_13634"
                                              data-name="Path 13634"
                                              d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                              transform="translate(-83.869 402.606)"
                                            />
                                          </svg>
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Remove Course</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this Course from ecommerce?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={handleDeleteCourse}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EstoreCoursesList;
