import React, { useEffect, useState } from "react";

import { PDFViewer } from "@react-pdf/renderer";

import { PDFDownloadLink } from "@react-pdf/renderer";
import CertService from "../../services/cert.service";

import CertificatePreview from "./CertificatePreview";

import { ColorRing } from "react-loader-spinner";

const AdminCertPreview = ({
  cerImgSrc,
  currentUser,
  courseName,
  cid,
  userId,
  certLoading,
  catid,
  awarded_on,
  code,
  grade,
}) => {
  const [addedElements, setAddedElements] = useState([]);

  useEffect(() => {
    CertService.getCertEleByCorsId(cid)
      .then((resp) => {
        setAddedElements(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  let courseShortName = "";

  console.log("cerImgSrc", cerImgSrc);

  if (courseName) {
    const words = courseName.split(" ");
    const initials = words.map((word) => word[0]).join("");
    courseShortName = initials.toUpperCase();
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date(awarded_on);
  const monthName = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const formattedDate = `${monthName} ${day}, ${year}`;

  const shareFile = (e, file, url) => {
    const formdata = new FormData();

    formdata.append("corsid", cid);
    formdata.append("uid", userId);
    formdata.append("cert", file, `${currentUser}-${courseName}.pdf`);
    formdata.append("certname", `${currentUser}-${courseName}.pdf`);

    CertService.uploadCert(formdata)
      .then((resp) => {})
      .catch((e) => console.log(e));
  };

  return (
    <div className="cert-flex">
      {certLoading ? (
        <>
          <div className="loading load-anim">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </>
      ) : (
        <>
          {/* <PDFViewer width="100%" height="410px">
            <CertificatePreview
              cerImgSrc={cerImgSrc}
              currentUser={currentUser}
              courseName={courseName}
              formattedDate={formattedDate}
              courseShortName={courseShortName}
              catid={catid}
              awarded_on={awarded_on}
              code={code}
              grade={grade}
              addedElements={addedElements}
            />
          </PDFViewer> */}
          <PDFDownloadLink
            document={
              <CertificatePreview
                cerImgSrc={cerImgSrc}
                currentUser={currentUser}
                courseName={courseName}
                formattedDate={formattedDate}
                courseShortName={courseShortName}
                catid={catid}
                awarded_on={awarded_on}
                code={code}
                grade={grade}
                addedElements={addedElements}
              />
            }
            fileName={`${currentUser}-${courseName}.pdf`}
            className="cert-down admin"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading..."
              ) : (
                <button onClick={(e) => shareFile(e, blob, url)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.2"
                    height="15.119"
                    viewBox="0 0 14.2 15.119"
                  >
                    <g id="download" transform="translate(-15.9 511.769)">
                      <path
                        id="Path_13771"
                        data-name="Path 13771"
                        d="M131.132-511.734a.5.5,0,0,0-.248.263c-.024.056-.033,1.065-.033,3.2v3.117h-.982c-.9,0-1,.006-1.106.056a.418.418,0,0,0-.263.42.367.367,0,0,0,.1.3c.056.071.837.967,1.736,1.993,1.121,1.283,1.665,1.881,1.733,1.916a.465.465,0,0,0,.4,0c.068-.035.612-.633,1.733-1.916.9-1.026,1.68-1.922,1.736-1.993a.36.36,0,0,0,.1-.3.347.347,0,0,0-.092-.284c-.145-.177-.183-.183-1.275-.186l-.985,0-.006-3.161c-.009-3.143-.009-3.164-.071-3.244a.807.807,0,0,0-.142-.142c-.077-.059-.121-.062-1.165-.068A5.711,5.711,0,0,0,131.132-511.734Z"
                        transform="translate(-109.27 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_13772"
                        data-name="Path 13772"
                        d="M15.909-158l.009,2,.077.166a.946.946,0,0,0,.467.467l.166.077H29.373l.166-.077a.946.946,0,0,0,.467-.467l.077-.166.009-2,.009-2H28.2v2.839H17.8V-160H15.9Z"
                        transform="translate(0 -341.366)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </button>
              )
            }
          </PDFDownloadLink>
        </>
      )}
    </div>
  );
};

export default AdminCertPreview;
