import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
// import "../SuperAdmin/Dashboard/dashboard.css";
import "./learn.css";

Chart.register(...registerables);

function MyProgressChart() {
  const [resolvedReq, setResolvedReq] = useState("20");
  const [unresolvedReq, setUnresolvedReq] = useState("12");
  const [inprogressReq, setInprogressReq] = useState("9");

  const [pieData, setPieData] = useState([50, 25, 15, 10]);

  var style = getComputedStyle(document.body);
  var whiteColor = style.getPropertyValue("--whiteColor");

  const data = {
    labels: ["Pilot", "Aviation", "DHP", "GRF"],
    datasets: [
      {
        label: "progress",
        data: pieData,
        backgroundColor: ["#0058FF", "#21D59B", "#FFC700", "#F99600"],
        borderColor: ["transparent"],
        hoverOffset: 4,
      },
    ],
  };

  const centerText = {
    id: "",
  };

  const options = {
    cutout: 75,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      centerText: [centerText],
    },
  };
  return (
    <div className="main-donut-container">
      <h6 style={{ textAlign: "left" }}>My Progress</h6>
      <div className="donut-container">
        <div>
          <Doughnut
            data={data}
            options={options}
            width="170px"
            height="170px"
          />
        </div>
        <div className="donut-data">
          <div className="donutDetails">
            <div className="donutDetails-flex">
              <div className="donutDetails-color do-color1"></div>
              <span>Pilot</span>
            </div>
            <span>{pieData[0]}%</span>
          </div>
          <div className="donutDetails">
            <div className="donutDetails-flex">
              <div className="donutDetails-color do-color2"></div>
              <span>Aviation</span>
            </div>
            <span>{pieData[1]}%</span>
          </div>
          <div className="donutDetails">
            <div className="donutDetails-flex">
              <div className="donutDetails-color do-color3"></div>
              <span>DHP</span>
            </div>
            <span>{pieData[2]}%</span>
          </div>
          <div className="donutDetails">
            <div className="donutDetails-flex">
              <div className="donutDetails-color do-color4"></div>
              <span>GRF</span>
            </div>
            <span>{pieData[3]}%</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProgressChart;
