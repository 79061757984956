import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import CourseService from "../services/course.service";

import CourseImg from "../assets/images/course-placeholder.png";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { ColorRing } from "react-loader-spinner";

const RecommendedCourses = () => {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    CourseService.getCourse(user.user)
      .then((response) => {
        setCourses(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1024: {
        items: 1.5,
      },
      1280: {
        items: 2.5,
      },
      1366: {
        items: 2.75,
      },
      1536: {
        items: 3.15,
      },
      1920: {
        items: 4,
      },
    },
  };

  return (
    <>
      <div className="inner-box recom-courses">
        <div className="pathway-item">
          <div className="course-lists pathway">
            {loading ? (
              <>
                <div className="loading">
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              </>
            ) : (
              <>
                <OwlCarousel className="owl-theme" {...options} loop>
                  {Array.isArray(courses) && courses.length > 0
                    ? courses.map((course) => (
                        <div className="item course-item" key={course.id}>
                          <div className="course-img">
                            {course.course_image ? (
                              <>
                                <img
                                  src={
                                    "https://lms.aeonsoftware.net/cit/" +
                                    course.course_image
                                  }
                                  alt=""
                                />
                              </>
                            ) : (
                              <>
                                <img src={CourseImg} alt="" />
                              </>
                            )}
                          </div>
                          <div className="course-info">
                            <div className="cinfo">
                              <span className="cname">{course.name}</span>
                              {/* <div className="course-act">
                            <button>View Course</button>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </OwlCarousel>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendedCourses;
