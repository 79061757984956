import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./paymentPages.css";
import failureImg from "../../assets/images/exclamation_9394847.png";

const PaymentFailure = () => {
  const [showSucess, setShowSucess] = useState(false);

  const handleClose = () => {
    setShowSucess(false);
    window.location.href = "https://lms.aeonsoftware.net/";
  };

  const handleShow = (e) => {
    setShowSucess(true);
  };

  useEffect(() => {
    handleShow();
  }, []);

  return (
    <>
      <div className="success">
        {/* <button onClick={handleShow}>Show</button> */}
        <Modal show={showSucess} onHide={handleClose} centered>
          <Modal.Body>
            <div className="sucess-modal failure-img">
              <img src={failureImg} alt="sucess" />
              <div className="payment-success">
                <span>Rejected!</span>
                <div className="payent-details">
                  <h5>Transaction ID: 10001000</h5>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PaymentFailure;
