import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

function CourseOverviewChart({ courseEnrolmentData, mode, chnageMode }) {
  var style = getComputedStyle(document.body);
  var whiteColor = style.getPropertyValue("--whiteColor");

  //gradient code
  const gradient = (context) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      // This can happen when the chart collapses to 0 height
      return null;
    }

    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );

    if (chnageMode === "light") {
      // gradient.addColorStop(0, "#FFFFFF38"); // Start color
      gradient.addColorStop(0, "rgba(55, 104, 243, 0.3)"); // Start color
      gradient.addColorStop(1, "rgba(55, 104, 243, 1)"); // End color (transparent)
    } else {
      gradient.addColorStop(0, "rgb(46, 46, 87)"); // Start color
      gradient.addColorStop(1, "blue"); // End color (transparent)
    }

    return gradient;
  };

  const data = {
    labels: courseEnrolmentData, //x-axis entry
    datasets: [
      {
        label: "Learning Overview",
        data: [4, 3, 2, 5, 3, 7, 3, 6, 2, 4],
        backgroundColor: gradient,
        borderColor: "white",
        color: "#FFFFFF",
        fill: true,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    tension: 0.4,
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          color: `${chnageMode === "light" ? "#010001" : "#f5f5f5"}`,
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        title: {
          display: true,
          text: "Time",
        },
        grid: {
          drawOnChartArea: false,
        },
        stacked: true,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
      style={{ height: "235px", width: "500px" }}
    />
  );
}

export default CourseOverviewChart;
