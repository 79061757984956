import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CourseModules from "../../common/CourseModules";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import AuthService from "../../services/auth.service";
import SettingsService from "../../services/settings.service";
import CourseService from "../../services/course.service";
import CartService from "../../services/cart.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Cart.css";

import CourseImg from "../../assets/images/course-placeholder.png";

const CatalogCourseOverview = ({ fetchCartCourseData }) => {
  const location = useLocation();
  const { cid, enroll, price } = location.state;

  const [currentCourse, setCurrentCourse] = useState({
    coursename: "",
    courseimg: "",
    coursedescription: "",
  });

  const [paymentLink, setPaymentLink] = useState("");

  const [loading, setLoading] = useState(false);

  const getCurrentCourse = (user, cid) => {
    CourseService.getCourseById(user, cid)
      .then((resp) => {
        setCurrentCourse({
          coursename: resp.data.course_info[0].name,
          courseimg: resp.data.course_info[0].course_image,
          coursedescription: resp.data.course_info[0].description,
        });
      })
      .catch((e) => console.log(e));
  };

  const addCoursesToCart = () => {
    const user = AuthService.getCurrentUser();
    const data = {
      usp: user.user,
      courid: cid,
    };
    CartService.addCourseToCart(data)
      .then((response) => {
        if (response.data === true) {
          toast.success("Added to cart!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchCartCourseData(user.user);
        } else {
          toast.warn("This course is already is cart!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // fetchCartCourseData(user.user);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    getCurrentCourse(user.user, cid);
  }, []);

  // const BuyCourse = () => {
  //   window.location.href = "https://codefrontend.com";
  // };

  const buyCourse = async (cid, price) => {
    const user = AuthService.getCurrentUser();

    const numericPrice = parseFloat(price);

    if (isNaN(numericPrice)) {
      console.error("Invalid price format");
      return;
    }

    const coursePrice = numericPrice.toFixed(2);

    const data = {
      amt: coursePrice,
      csid: cid,
      usid: user.user,
    };

    CartService.buyCartCourse(data)
      .then((response) => {
        const resData = response.data;
        const plink = resData.split(" ");
        const uplink = plink[26].replace('}\n}\n{"plink":"', "");
        const flink = uplink.replace("}", "");
        const nflink = flink.replace('"', "");

        setPaymentLink(nflink);

        window.location.href = nflink;
        // window.open(`${nflink}`, "_blank");
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="row">
      <ToastContainer />
      <div className="row">
        <div className="col-xl-3 col-lg-3" style={{ paddingRight: 0 }}>
          <div className="outer-box">
            <div className="action-flex mb-4">
              <Link to="/Catalog">
                <div className="action-item">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 19.379 19.382"
                    >
                      <g
                        id="left-arrow-in-circular-button-black-symbol"
                        transform="translate(-0.6 511.567)"
                      >
                        <path
                          id="Path_13057"
                          data-name="Path 13057"
                          d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                          transform="translate(0 0)"
                        />
                      </g>
                    </svg>
                  </span>
                  <span>Back</span>
                </div>
              </Link>
            </div>
            <div className="course-info cat-course-info">
              <div className="course-name">
                <span>{currentCourse.coursename}</span>
              </div>
              <div className="course-modules">
                <label htmlFor="">Course Modules :-</label>
                <CourseModules cid={cid} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-9" style={{ paddingRight: 0 }}>
          <div className="row mb-3">
            <div className="col-xl-12 col-lg-12">
              <div className="outer-box">
                <div className="course-overview">
                  <div className="course-img">
                    {currentCourse.courseimg !== "" ? (
                      <>
                        <img
                          src={
                            "https://aeonsoftware.net/aeonlms/cit/" +
                            currentCourse.courseimg
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img src={CourseImg} width="500px" alt="" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-xl-12">
              <div className="outer-box">
                <div className="cflex">
                  <div className="heading">
                    <h1>Course Overview</h1>
                  </div>
                </div>
                <p>{currentCourse.coursedescription}</p>

                {enroll === null ? (
                  <div className="cart-btn-details">
                    <div className="buy-btn">
                      <button onClick={() => buyCourse(cid, price)}>
                        Buy Now
                      </button>
                    </div>
                    <div className="add-cart-btn">
                      <button onClick={addCoursesToCart}>Add to cart</button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogCourseOverview;
