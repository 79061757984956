import http from "../http-common";

const API_URL = "/api/";

const login = (email, password) => {
  return http
    .post(API_URL + 'login-users' , {
      email,
      password,
    })
    .then((response) => {
      const token = response.data.token;
      if (token) {
        localStorage.setItem("token", JSON.stringify(response.data.token));
        localStorage.setItem("occsts", JSON.stringify(response.data.occsts));
        localStorage.setItem("id", JSON.stringify(response.data));
      }


      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("id");
  localStorage.removeItem("token");
  localStorage.removeItem("occsts");
  localStorage.clear();
};

const getToken = () => {
  return JSON.parse(localStorage.getItem('token'));
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('id'));
}


const AuthService = {
  login,
  logout,
  getToken,
  getCurrentUser
};

export default AuthService;