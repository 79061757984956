import React, { useState, useEffect } from "react";
import "./topbar.css";
import Settings from "./Settings";
import Notification from "./Notification";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";

import { useSelector, useDispatch } from "react-redux";

import CartService from "../../services/cart.service";
import Language from "./Language";
import CourseCart from "../Learner/CourseCart";

import { setTheme } from "../../actions/themeActions";

import { useTranslation } from "react-i18next";

const languagesArr = [
  { value: "", text: "Options" },
  { value: "en", text: "English" },
  { value: "ar", text: "Arabic" },
  { value: "de", text: "German" },
  { value: "es", text: "Spanish" },
];

const Topbar = ({ logout, toggleMenu, cartCourses, fetchCartCourseData }) => {
  const themeMode = useSelector((state) => state.themeReducer.themeMode);

  const location = useLocation();

  const dispatch = useDispatch();

  const [role, setRole] = useState(false);
  const [course, setCourse] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [settings, setSettings] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [languages, setLanguages] = useState(false);
  const [cart, setCart] = useState(false);

  const showSettingsMenu = () => {
    setSettings(true);
  };
  const hideSettingsMenu = () => {
    setSettings(false);
  };

  const showCart = () => {
    setCart(!cart);
  };

  // const hideCart = () => {
  //   setCart(false);
  // };

  const showLanguagesMenu = () => {
    setLanguages(true);
  };
  const hideLanguagesMenu = () => {
    setLanguages(false);
  };

  const showNotifications = () => {
    setNotifications(true);
  };
  const hideNotifications = () => {
    setNotifications(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setRole(user.role_id);
    }
  }, []);

  // useEffect(() => {
  //   fetchCartCourseData();
  // }, [cartCourseCount]);

  const [topbarMode, setTopbarMode] = useState(themeMode);

  const changeMode = () => {
    if (topbarMode === "0") {
      setTopbarMode("1");
      const newThemeMode = "1";
      dispatch(setTheme(newThemeMode));
    } else {
      setTopbarMode("0");
      const newThemeMode = "0";
      dispatch(setTheme(newThemeMode));
    }
  };

  return (
    <>
      <div className="topbar">
        {/* Onboard */}
        <div className="sidebar-toggle" onClick={toggleMenu}>
          <span
            className={
              location.pathname.includes("/Onboard")
                ? "icon__toggle d-none"
                : "icon__toggle"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30.676"
              height="19.575"
              viewBox="0 0 30.676 19.575"
            >
              <g
                id="Group_10859"
                data-name="Group 10859"
                transform="translate(-19 -154)"
              >
                <path
                  id="Path_416"
                  data-name="Path 416"
                  d="M15.369,1.724c-4.78,0-9.56-.009-14.34.013-.506,0-.694-.39-.969-.7A.45.45,0,0,1,.043.732,1.338,1.338,0,0,1,.941,0H29.766a1.3,1.3,0,0,1,.91.715.611.611,0,0,1-.006.314,1.3,1.3,0,0,1-.925.695Z"
                  transform="translate(18.977 154)"
                />
                <path
                  id="Path_417"
                  data-name="Path 417"
                  d="M15.381,160.016c4.767,0,9.534.011,14.306-.016.5,0,.719.334.988.654a.485.485,0,0,1,.032.343,1.312,1.312,0,0,1-.9.721H.982a1.329,1.329,0,0,1-.911-.711.592.592,0,0,1,.007-.314,1.247,1.247,0,0,1,.931-.677Z"
                  transform="translate(18.953 11.856)"
                />
                <path
                  id="Path_419"
                  data-name="Path 419"
                  d="M13.67,81.763c-4.2,0-8.4-.01-12.594.012-.547,0-.748-.389-1.039-.688-.054-.056-.041-.214-.014-.309a1.459,1.459,0,0,1,1.008-.718H26.26a1.469,1.469,0,0,1,1.028.692.526.526,0,0,1,0,.314,1.465,1.465,0,0,1-1.026.7Z"
                  transform="translate(19 82.875)"
                />
              </g>
            </svg>
          </span>{" "}
          &nbsp;
          {role === "1" ? (
            <>
              <span className="role_name">Welcome, Super Admin</span>
            </>
          ) : role === "2" ? (
            <>
              <span className="role_name">Welcome, Admin</span>
            </>
          ) : role === "3" ? (
            <>
              <span className="role_name">Welcome, Instructor</span>
            </>
          ) : (
            <>
              <span className="role_name">Welcome, {currentUser.name}</span>
            </>
          )}
        </div>
        <div className="flex">
          <div className="custom_theme">
            <label className="switch">
              <input
                type="checkbox"
                id="theme"
                name="theme"
                checked={topbarMode === "1"}
                onChange={() => {
                  changeMode();
                }}
              />
              <span className="slider round" title="Change Theme"></span>
            </label>
          </div>
          <div className="notifications">
            <span
              className="topbar__icon notify_icon"
              id="one"
              onClick={showNotifications}
              title="Notifications"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.154"
                height="24"
                viewBox="0 0 22.154 24"
              >
                <g
                  id="Group_10890"
                  data-name="Group 10890"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_11729"
                    data-name="Path 11729"
                    d="M281.267-511.857a4.939,4.939,0,0,0-2.671,1.394,4.937,4.937,0,0,0-1.455,3.766,4.865,4.865,0,0,0,1.31,3.12,4.876,4.876,0,0,0,2.119,1.371,4.559,4.559,0,0,0,1.787.225,4.926,4.926,0,0,0,4.6-3.766,6.08,6.08,0,0,0,.065-2.082,5.084,5.084,0,0,0-2.8-3.635A5.119,5.119,0,0,0,281.267-511.857Z"
                    transform="translate(-264.932 511.929)"
                    fill="red"
                  />
                  <path
                    id="Path_11730"
                    data-name="Path 11730"
                    d="M30.473-468.876a7.043,7.043,0,0,0-3.982,2.072,6.913,6.913,0,0,0-1.847,3.448,14.023,14.023,0,0,0-.137,2.76,13.8,13.8,0,0,1-.137,2.746,6.767,6.767,0,0,1-2.088,3.612c-.639.608-.781.884-.781,1.5a1.3,1.3,0,0,0,.128.688,1.9,1.9,0,0,0,1.383,1.015c.379.07,16.757.075,17.121,0a1.955,1.955,0,0,0,1.4-1.043,1.242,1.242,0,0,0,.128-.664c0-.632-.109-.828-.8-1.511a10.827,10.827,0,0,1-.866-.95,6.681,6.681,0,0,1-1.288-3.265l-.057-.505L38.2-459a7.071,7.071,0,0,1-6.255-4.776,6.223,6.223,0,0,1-.35-2.175,6.666,6.666,0,0,1,.507-2.624c.066-.168.123-.323.123-.337A8.814,8.814,0,0,0,30.473-468.876Z"
                    transform="translate(-21.5 470.954)"
                  />
                  <path
                    id="Path_11731"
                    data-name="Path 11731"
                    d="M156-63.981a5.622,5.622,0,0,0,.313.894,3.85,3.85,0,0,0,2.386,1.951,4.574,4.574,0,0,0,1.885.019,3.986,3.986,0,0,0,1.965-1.258,4.742,4.742,0,0,0,.72-1.38l.07-.243h-3.672C157.651-64,156-63.991,156-63.981Z"
                    transform="translate(-149.589 85.029)"
                  />
                </g>
              </svg>
            </span>
          </div>
          {/* <div className="lang">
            <span className="topbar__icon" id="two" onClick={showLanguagesMenu}>
              <svg
                id="Group_10891"
                data-name="Group 10891"
                xmlns="http://www.w3.org/2000/svg"
                width="22.154"
                height="22.154"
                viewBox="0 0 22.154 22.154"
              >
                <path
                  id="Path_11661"
                  data-name="Path 11661"
                  d="M348,359.077v-9.389c.017-.061.034-.123.05-.185a1.881,1.881,0,0,1,.935-1.24,5.154,5.154,0,0,1,.7-.263h9.389a.153.153,0,0,0,.037.022,1.957,1.957,0,0,1,1.649,2.128q0,4.27,0,8.541a1.955,1.955,0,0,1-2.073,2.072c-1.11,0-2.22,0-3.33,0a.292.292,0,0,0-.3.169,4.672,4.672,0,0,1-3.38,2.409.653.653,0,0,1-.813-.66c-.006-.216,0-.432,0-.649,0-.416,0-.831,0-1.267-.341,0-.656.008-.971,0a1.953,1.953,0,0,1-1.791-1.332C348.061,359.313,348.033,359.194,348,359.077Zm8.308-5.614h.359c.115,0,.231,0,.346,0A.636.636,0,0,0,357.6,353a.649.649,0,0,0-.668-.836c-.555-.008-1.11,0-1.666,0-.076,0-.151-.008-.244-.013,0-.137,0-.252,0-.366a.647.647,0,1,0-1.294,0c-.006.119,0,.239,0,.367-.088,0-.145.009-.2.009-.562,0-1.125,0-1.687,0a.667.667,0,0,0-.719.648.657.657,0,0,0,.713.648c.2,0,.4,0,.617,0a4.808,4.808,0,0,0,.922,2.452,4.209,4.209,0,0,1-1.622.428.65.65,0,0,0-.629.665.658.658,0,0,0,.716.625,5.112,5.112,0,0,0,2.378-.705.269.269,0,0,1,.326,0,5.122,5.122,0,0,0,2.379.7.648.648,0,1,0,.085-1.292c-.122-.011-.245-.018-.366-.037a3.811,3.811,0,0,1-1.255-.4A4.809,4.809,0,0,0,356.308,353.463Z"
                  transform="translate(-348 -348)"
                />
                <path
                  id="Path_11662"
                  data-name="Path 11662"
                  d="M521.85,481.233h-9.389a.266.266,0,0,0-.057-.029,1.943,1.943,0,0,1-1.629-2.057q0-2.877,0-5.754v-.254c.5,0,.984.006,1.465,0a3.118,3.118,0,0,0,1.856-.616,3.192,3.192,0,0,0,1.346-2.609c.011-.474,0-.948,0-1.412.035-.016.047-.026.06-.026.231,0,.462-.013.692,0a.266.266,0,0,0,.283-.167,4.68,4.68,0,0,1,3.381-2.411.653.653,0,0,1,.815.658c.005.166,0,.332,0,.5,0,.466,0,.933,0,1.421.339,0,.656-.014.97,0a1.887,1.887,0,0,1,1.688,1.093,4.531,4.531,0,0,1,.2.588v9.389c-.016.055-.033.109-.047.164a1.882,1.882,0,0,1-.958,1.271A5.158,5.158,0,0,1,521.85,481.233Zm-1.439-3.837a1.7,1.7,0,0,0-.092-.274q-1.276-2.56-2.557-5.118a.655.655,0,0,0-1.207-.006q-1.283,2.57-2.568,5.138a.65.65,0,0,0,.262.893.642.642,0,0,0,.893-.307c.134-.255.251-.519.394-.768a.312.312,0,0,1,.222-.139c.93-.009,1.86,0,2.789-.009a.232.232,0,0,1,.244.157c.128.274.262.545.408.81a.629.629,0,0,0,.738.306A.67.67,0,0,0,520.411,477.4Z"
                  transform="translate(-501.384 -459.079)"
                />
                <path
                  id="Path_11663"
                  data-name="Path 11663"
                  d="M348,596.837a.933.933,0,0,0,.057-.084.649.649,0,0,1,1.241.3,4.582,4.582,0,0,0,4,4.5c.071.01.143.009.264.015-.078-.085-.13-.139-.179-.2a.655.655,0,0,1,.02-.9.637.637,0,0,1,.883-.02q.7.664,1.361,1.361a.618.618,0,0,1-.016.865c-.435.452-.881.893-1.332,1.329a1.581,1.581,0,0,1-.329.193h-.216a.251.251,0,0,0-.052-.036.649.649,0,0,1-.26-1.128c.04-.043.076-.091.128-.153-.061-.009-.088-.016-.116-.018a5.843,5.843,0,0,1-5.176-4.07c-.13-.408-.188-.838-.279-1.258Z"
                  transform="translate(-348 -582.039)"
                />
                <path
                  id="Path_11664"
                  data-name="Path 11664"
                  d="M604.1,355.269a.763.763,0,0,1-.479.514.643.643,0,0,1-.827-.631,4.665,4.665,0,0,0-.719-2.434,4.518,4.518,0,0,0-3.544-2.092c.068.074.117.126.165.18a.655.655,0,0,1-.007.9.64.64,0,0,1-.914.008q-.669-.646-1.315-1.315a.621.621,0,0,1,0-.9c.431-.445.872-.882,1.316-1.314a1.612,1.612,0,0,1,.309-.189h.3a.282.282,0,0,0,.049.038.648.648,0,0,1,.213,1.107c-.042.044-.08.091-.136.154.1.013.171.025.242.032a5.842,5.842,0,0,1,5.184,4.553c.072.314.108.635.16.953Z"
                  transform="translate(-581.942 -348)"
                />
                <path
                  id="Path_11669"
                  data-name="Path 11669"
                  d="M448.016,442.875h1.19a2.758,2.758,0,0,1-.588,1.594A3.266,3.266,0,0,1,448.016,442.875Z"
                  transform="translate(-442.246 -437.401)"
                />
                <path
                  id="Path_11670"
                  data-name="Path 11670"
                  d="M605.832,603.214l.9-1.8.9,1.8Z"
                  transform="translate(-590.957 -586.798)"
                />
              </svg>
            </span>
          </div> */}
          {/* <h1>{t("welcome")}</h1>
          <label>{t("choose")}</label>
          <select value={lang} onChange={handleChange}>
            {languagesArr.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.text}
                </option>
              );
            })}
          </select> */}
          <div className="help">
            <Link to="/HelpNSupport" title="Help and Support">
              <span className="topbar__icon" id="three">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.154"
                  height="22.154"
                  viewBox="0 0 22.154 22.154"
                >
                  <path
                    id="Path_11752"
                    data-name="Path 11752"
                    d="M389.785,377.4V378.7c-.057.419-.1.841-.173,1.256a11.071,11.071,0,0,1-21.956-1.44A10.184,10.184,0,0,1,369.2,372.4a10.9,10.9,0,0,1,7.6-5.227c.416-.077.84-.115,1.26-.171h1.3a1.172,1.172,0,0,0,.167.035,10.425,10.425,0,0,1,4.4,1.272,10.885,10.885,0,0,1,5.684,7.841C389.691,376.563,389.729,376.984,389.785,377.4ZM383,374.988a3.087,3.087,0,0,0-.954-2.239,4.04,4.04,0,0,0-2.338-1.112,4.854,4.854,0,0,0-4.033,1.028,3.342,3.342,0,0,0-1.248,2.377,1.05,1.05,0,0,0,.564,1.018,1.082,1.082,0,0,0,1.6-.6c.131-.319.242-.646.393-.955a1.784,1.784,0,0,1,.833-.928,2.273,2.273,0,0,1,1.727-.075,1.525,1.525,0,0,1,.768,2.4,3.806,3.806,0,0,1-.377.414c-.529.5-1.076.979-1.592,1.491a2.982,2.982,0,0,0-.88,2.372,1.006,1.006,0,0,0,.972,1.02.962.962,0,0,0,1.085-.828c.031-.125.067-.25.091-.377a2.042,2.042,0,0,1,.648-1.165c.563-.515,1.126-1.031,1.677-1.558A3,3,0,0,0,383,374.988Zm-3.118,8.292a1.251,1.251,0,0,0-1.241-1.3,1.271,1.271,0,0,0-1.336,1.259,1.289,1.289,0,0,0,2.577.043Z"
                    transform="translate(-367.631 -367)"
                  />
                </svg>
              </span>
            </Link>
          </div>
          {role === "4" ? (
            <div className="cart" title="Cart">
              {/* <Link to="/Cart"> */}
              <span className="topbar__icon" id="three-2" onClick={showCart}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 23.181 23.6"
                >
                  <g
                    id="Group_11908"
                    data-name="Group 11908"
                    transform="translate(12954.303 19815.703)"
                  >
                    <g
                      id="Group_11904"
                      data-name="Group 11904"
                      transform="translate(-12943.689 -19815.285)"
                    >
                      <path
                        id="Path_21698"
                        data-name="Path 21698"
                        d="M239.475-.5h1.494a6.414,6.414,0,0,1,5.139,4.03,8,8,0,0,1,.385,1.494V6.518a6.346,6.346,0,0,1-3.984,5.139,6.393,6.393,0,0,1-6.565-1.222,6.382,6.382,0,0,1-1.517-7.176A6.4,6.4,0,0,1,239.475-.5Z"
                        transform="translate(-233.925 0.5)"
                        fill="red"
                        fillRule="evenodd"
                        opacity="0.985"
                      />
                    </g>
                    <g
                      id="Group_11905"
                      data-name="Group 11905"
                      transform="translate(-12954.303 -19813.383)"
                    >
                      <path
                        id="Path_21699"
                        data-name="Path 21699"
                        d="M-.5,42.556v-.543a.941.941,0,0,1,.543-.475,30.283,30.283,0,0,1,3.169.045,2.739,2.739,0,0,1,1.924,1.562,20.494,20.494,0,0,1,.521,2.264H8.238A8.174,8.174,0,0,0,19.2,53.581a8.468,8.468,0,0,0,2.173-1.2q-.263,1.532-.611,3.079a.716.716,0,0,1-.521.566l-14.851.045a.694.694,0,0,0-.362,1.155.876.876,0,0,0,.317.2l14.851.045a.762.762,0,0,1,.43,1.2.6.6,0,0,1-.385.249q-7.538.045-15.077,0a2.129,2.129,0,0,1-1.788-1.788,2.176,2.176,0,0,1,1.653-2.513q.52-.063,1.041-.113-1.139-5.365-2.286-10.73a1.156,1.156,0,0,0-.792-.7Q1.5,42.99,0,42.986A.79.79,0,0,1-.5,42.556Z"
                        transform="translate(0.5 -41.515)"
                        // fill="#21e0fb"
                        fillRule="evenodd"
                        opacity="0.978"
                      />
                    </g>
                    <g
                      id="Group_11906"
                      data-name="Group 11906"
                      transform="translate(-12945.617 -19795.016)"
                    >
                      <path
                        id="Path_21700"
                        data-name="Path 21700"
                        d="M193.142,450.111h-.724a1.493,1.493,0,0,1-.272-2.785,1.53,1.53,0,0,1,1.879.521,1.555,1.555,0,0,1-.43,2.06Q193.365,450.017,193.142,450.111Z"
                        transform="translate(-191.323 -447.2)"
                        // fill="#21e0fb"
                        fillRule="evenodd"
                        opacity="0.966"
                      />
                    </g>
                    <g
                      id="Group_11907"
                      data-name="Group 11907"
                      transform="translate(-12937.89 -19795.016)"
                    >
                      <path
                        id="Path_21701"
                        data-name="Path 21701"
                        d="M363.832,450.093h-.724a1.483,1.483,0,1,1,.724-2.875,1.472,1.472,0,0,1,1.064,1.743A1.608,1.608,0,0,1,363.832,450.093Z"
                        transform="translate(-361.998 -447.18)"
                        // fill="#21e0fb"
                        fillRule="evenodd"
                        opacity="0.965"
                      />
                    </g>
                    <text
                      id="_2"
                      data-name="2"
                      transform="translate(-12939.712 -19805.703)"
                      fill="#fafafa"
                      fontSize="10"
                      fontFamily="SourceSansPro-Bold, Source Sans Pro"
                      fontWeight="700"
                    >
                      <tspan x="0" y="0">
                        {cartCourses ? cartCourses.length : "0"}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </span>
              {/* </Link> */}
            </div>
          ) : (
            <></>
          )}
          <div className="settings" title="Settings">
            <span className="topbar__icon" id="four" onClick={showSettingsMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.154"
                height="22.154"
                viewBox="0 0 22.154 22.154"
              >
                <g id="Layer_2" data-name="Layer 2" transform="translate(0 0)">
                  <g
                    id="Layer_1"
                    data-name="Layer 1"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_11559"
                      data-name="Path 11559"
                      d="M13.475,22.154h-4.8a1.175,1.175,0,0,1-.828-1.1c-.074-.709-.2-1.412-.3-2.118a.31.31,0,0,0-.186-.261c-.4-.21-.785-.433-1.168-.661a.321.321,0,0,0-.324-.027c-.723.29-1.453.572-2.181.854a1.026,1.026,0,0,1-1.413-.465q-1.047-1.747-2.081-3.5a1,1,0,0,1,.294-1.446c.612-.466,1.224-.934,1.84-1.4a.305.305,0,0,0,.135-.289c-.011-.439-.013-.879,0-1.318a.322.322,0,0,0-.145-.307C1.7,9.657,1.09,9.189.478,8.723A.99.99,0,0,1,.184,7.3Q1.223,5.533,2.272,3.777a1.04,1.04,0,0,1,1.4-.463c.728.282,1.457.564,2.181.853A.341.341,0,0,0,6.2,4.142q.564-.341,1.149-.65a.331.331,0,0,0,.2-.277c.106-.759.223-1.512.329-2.268A1.017,1.017,0,0,1,8.967,0h4.222a1.01,1.01,0,0,1,1.088.946q.163,1.146.345,2.288a.394.394,0,0,0,.176.237c.383.231.782.438,1.164.67a.341.341,0,0,0,.345.024c.717-.286,1.44-.565,2.159-.844a1.033,1.033,0,0,1,1.428.474q1.036,1.738,2.066,3.48a1,1,0,0,1-.288,1.448c-.612.466-1.224.935-1.839,1.4a.306.306,0,0,0-.142.286q.017.67,0,1.339a.3.3,0,0,0,.14.287c.616.461,1.227.929,1.839,1.4a1,1,0,0,1,.291,1.447q-1.03,1.742-2.065,3.481a1.037,1.037,0,0,1-1.428.479c-.728-.283-1.457-.563-2.181-.853a.323.323,0,0,0-.324.028c-.384.229-.779.439-1.164.67a.392.392,0,0,0-.175.234c-.125.768-.239,1.539-.347,2.31a1,1,0,0,1-.729.888.351.351,0,0,0-.071.042Zm-2.4-7.389a3.689,3.689,0,1,0-3.792-3.679A3.738,3.738,0,0,0,11.079,14.765Z"
                      transform="translate(0.001)"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </div>
          <div className="logout" title="Logout">
            <span className="topbar__icon" id="five" onClick={logout}>
              <svg
                id="Group_10892"
                data-name="Group 10892"
                xmlns="http://www.w3.org/2000/svg"
                width="22.154"
                height="22.154"
                viewBox="0 0 22.154 22.154"
              >
                <path
                  id="Path_11671"
                  data-name="Path 11671"
                  d="M370.785,348h13.5a.454.454,0,0,0,.09.035,2.565,2.565,0,0,1,2.4,2.388q.027,1.5-.006,3.006a.947.947,0,0,1-.217.536.985.985,0,0,1-1.031.25.817.817,0,0,1-.622-.791c0-.98,0-1.961,0-2.941,0-.556-.392-.891-1.048-.892q-5.42,0-10.841,0c-.06,0-.119.012-.224.023l.242.121c1.71.836,3.413,1.683,5.133,2.5a2.493,2.493,0,0,1,1.64,2.4c-.02,3.374-.007,6.748-.007,10.122v.274c.1,0,.16.009.219.01q1.9,0,3.81,0c.679,0,1.075-.342,1.076-.919,0-.851,0-1.7,0-2.552a.947.947,0,0,1,1.877-.013c.009.887.02,1.774,0,2.66A2.611,2.611,0,0,1,384,366.612c-1.294.035-2.59.011-3.886.013H379.8c0,.4.007.767,0,1.133a2.418,2.418,0,0,1-1.863,2.237c-.2.063-.4.107-.6.159h-.767c-.458-.164-.94-.293-1.37-.5-1.772-.846-3.52-1.726-5.292-2.572a2.475,2.475,0,0,1-1.622-2.366c.012-3.511,0-7.022,0-10.533,0-1.233-.006-2.466,0-3.7a2.319,2.319,0,0,1,1.476-2.172A7.115,7.115,0,0,1,370.785,348Z"
                  transform="translate(-368.281 -348)"
                />
                <path
                  id="Path_11672"
                  data-name="Path 11672"
                  d="M555.319,460.4H555.1q-2.382,0-4.763,0a.7.7,0,0,1-.778-.655.679.679,0,0,1,.677-.733c.538-.008,1.075,0,1.613,0h3.544c-.274-.272-.519-.5-.74-.744a.944.944,0,0,1-.219-.406.659.659,0,0,1,.359-.728.686.686,0,0,1,.792.111c.176.163.342.337.512.507q.7.7,1.409,1.409a.7.7,0,0,1,0,1.083c-.634.636-1.267,1.274-1.906,1.9a.685.685,0,0,1-.974.012.7.7,0,0,1-.013-.989c.056-.061.115-.118.174-.175l.558-.538Z"
                  transform="translate(-535.607 -450.471)"
                />
              </svg>
            </span>
          </div>
          <div className="user">
            <div className="circle">
              <Link to="/EditProfile" title="Edit Profile">
                <img
                  src={
                    "https://lms.aeonsoftware.net/cit/uploads/images/user.png"
                  }
                  className="user-pic"
                  alt=""
                  width="55px"
                />
                <div className="edit">
                  <img
                    src="./assets/images/edit-profile.png"
                    className="edit-pic"
                    alt=""
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="google_translate_element"></div> */}
      <CourseCart
        cart={cart}
        showCart={showCart}
        cartCourses={cartCourses}
        fetchCartCourseData={fetchCartCourseData}
      />
      <Settings settings={settings} hideSettingsMenu={hideSettingsMenu} />
      <Notification
        notifications={notifications}
        hideNotifications={hideNotifications}
      />
      <Language languages={languages} hideLanguagesMenu={hideLanguagesMenu} />
    </>
  );
};

export default Topbar;
